import { resolveProductLockRequestRepository } from '@di/app';
import { ProductLockRequestCreateModel } from '../../data/models';
import { createProductLockRequestUseCase } from '../use-cases/create-product-lock-request.use-case';
import { getProductLockRequestUseCase } from '../use-cases/product-lock-request.use-case';

export class ProductLockRequestUseCaseResolver {
  public static async productLockRequestsList(): ReturnType<typeof getProductLockRequestUseCase> {
    return getProductLockRequestUseCase(resolveProductLockRequestRepository());
  }

  public static async productLockRequestCreate(
    data: ProductLockRequestCreateModel,
  ): ReturnType<typeof createProductLockRequestUseCase> {
    return createProductLockRequestUseCase(data, resolveProductLockRequestRepository());
  }
}
